import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import Apis from "../../../Apis";
import axiosInstance from "../../../Apis/axiosInstance";
import ActionMenu from "../../../components/Common/ActionMenu";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PrimaryTable from "../../../components/Common/CommonTable/PrimaryTable";
import PrimaryInfoCard from "../../../components/Common/InfoCard/PrimaryInfoCard";

const AgentsList = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Agent List", link: "/agents-list" },
  ]);
  const [listData, setListData] = useState([
    {
      PARENT_ID: 1,
      BROKER_ID: 5,
      BROKER_LABEL: "Olive concentrate",
      // ACTION: (
      //   <ActionMenu
      //     items={[
      //       {
      //         name: "Add Agent",
      //         icon: <i className="ri-add-circle-fill text-success mx-1 h4" />,
      //         color: "warning",
      //         action: () => {},
      //       },
      //       {
      //         name: "Update Agent",
      //         icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
      //         color: "primary",
      //         action: () => {},
      //       },
      //       {
      //         name: "Delete Agent",
      //         icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
      //         color: "danger",
      //         action: () => {},
      //       },
      //     ]}
      //   />
      // ),
      VIEW_DETAILS: (
        <div className="d-flex justify-content-center" onClick={() => setSelectAgentId(23)}>
          <i className="ri-arrow-right-circle-line text-info mx-2 h4" />
        </div>
      ),
    },
  ]);
  const [detailsData, setDetailsData] = useState([
    { label: "NPN#", value: "256845" },
    { label: "Active", value: "Active" },
    { label: "First Name", value: "Tarzan" },
    { label: "Last Name", value: "Jos" },
    { label: "Email", value: "tarzan12@mailinator.com" },
    { label: "City", value: "Kolkata" },
    { label: "State", value: "Wast Bengal" },
    { label: "Phone 1", value: "362 256 2458" },
    { label: "Mobile", value: "362 256 2458" },
  ]);
  const [loading, setLoading] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [selectAgentId, setSelectAgentId] = useState();
  const columnsName = [
    {
      dataField: "PARENT_ID",
      text: "Parent Id",
    },
    {
      dataField: "BROKER_ID",
      text: "Agent Id",
    },
    {
      dataField: "BROKER_LABEL",
      text: "Label",
    },
    // {
    //   dataField: "ACTION",
    //   text: "Action",
    // },
    {
      dataField: "VIEW_DETAILS",
      text: "View Details",
    },
  ];

  // ---------------------------Get List Data--------------------------
  const getListData = () => {
    setLoading(true);
    axiosInstance
      .get(Apis.agent.list)
      .then((resp) => {
        console.log("ggg==>", resp);
        if (resp) {
          setListData(resp);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };
  useEffect(getListData, []);
  // -------------------------------------------------------------------

  const getDetailsData = () => {
    axiosInstance.get(Apis.agent.details(selectAgentId));
  };
  useEffect(getDetailsData, [selectAgentId]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Agents" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col md={6}>
              <PrimaryTable
                columnsName={columnsName}
                itemPerPage={itemPerPage}
                tableTitle="Agent List"
                tableData={listData}
                addButtonName="Add Agent"
                setItemPerPage={setItemPerPage}
                loading={loading}
              />
            </Col>
            <Col md={6}>
              <PrimaryInfoCard cardTitle="Details" detailsData={detailsData} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default AgentsList;
