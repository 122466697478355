import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Agents
import AgentsTree from "../pages/Agents/AgentsTree";
import AgentsList from "../pages/Agents/AgentsList";

// Plans
import Plans from "../pages/Plans";

// Product
import ProductList from "../pages/Product/ProductList";

// Settings
import Users from "../pages/Settings/Users";
import Apis from "../pages/Settings/Apis";

// User Profile
import UserProfile from "../pages/UserProfile";

// Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LicensesList from "../pages/Licenses/LicensesList";

// ------------------------------Auth Protected Routes---------------------------
const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", element: Dashboard },

  // Agents
  { path: "/agents-tree", element: AgentsTree },
  { path: "/agents-list", element: AgentsList },

  // Plans
  { path: "/plans", element: Plans },

  // Product
  { path: "/product/list", element: ProductList },

  // Licenses
  { path: "/licenses/list", element: LicensesList },

  // Settings
  { path: "/apis", element: Apis },
  { path: "/users", element: Users },

  // User Profile
  { path: "/user-profile/:name", element: UserProfile },

  // Utility
  { path: "/starter", element: StarterPage },
  { path: "/timeline", element: Timeline },
  { path: "/faqs", element: FAQs },
  { path: "/pricing", element: Pricing },

  // this route should be at the end of all other routes
  { path: "/", exact: true, element: () => <Navigate to="/dashboard" /> },
];
// ------------------------------------------------------------------------------

// ----------------------------------Public Routes-------------------------------
const publicRoutes = [
  { path: "/logout", element: Logout },
  { path: "/login", element: Login },
  { path: "/forgot-password", element: ForgetPwd },
  { path: "/register", element: Register },
  { path: "/lock-screen", element: AuthLockScreen },

  // Authentication Inner
  { path: "/auth-login", element: Login1 },
  { path: "/auth-register", element: Register1 },
  { path: "/auth-recoverpw", element: ForgetPwd1 },

  { path: "/maintenance", element: Maintenance },
  { path: "/comingsoon", element: CommingSoon },
  { path: "/404", element: Error404 },
  { path: "/500", element: Error500 },
];
// ------------------------------------------------------------------------------
export { authProtectedRoutes, publicRoutes };
