import React, { Component, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_INFO, CHANGE_PASSWORD } from "../../../constant/RouteURL/Profile";

const ProfileMenu = (props) => {
  let navigate = useNavigate();

  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(!menu);
  };

  let username = "Admin";
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const uNm = obj.email.split("@")[0];
    username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block user-dropdown">
        <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
          <img className="rounded-circle header-profile-user me-1" src={avatar2} alt="Header Avatar" />
          <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
          <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => navigate(`/user-profile/${ACCOUNT_INFO}`)}>
            <i className="ri-user-line align-middle me-1"></i> {props.t("Profile")}
          </DropdownItem>
          <DropdownItem onClick={() => navigate(`/user-profile/${CHANGE_PASSWORD}`)}>
            <i className="ri-lock-password-line align-middle me-1"></i> {props.t("Change Password")}
          </DropdownItem>
          {/* <DropdownItem className="d-block" href="#">
            <span className="badge badge-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-1"></i> {props.t("Settings")}
          </DropdownItem>
          <DropdownItem href="#">
            <i className="ri-lock-unlock-line align-middle me-1"></i> {props.t("Lock screen")}
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem className="text-danger" href="/logout">
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i> {props.t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
