import { useState } from "react";
import { Container } from "reactstrap";
import ActionMenu from "../../../components/Common/ActionMenu";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PrimaryTable from "../../../components/Common/CommonTable/PrimaryTable";

const ProductList = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Product List", link: "/product/list" },
  ]);
  const [itemPerPage, setItemPerPage] = useState(10);

  const tableData = [
    {
      parent_id: 1,
      agent_id: 5,
      category: "Baslet",
      product_label: "Fit e",
      description: " heart rate tracking, water resistant, longer battery.",
      active: "Active",
      defaultNoSaleStates: "Default No Sale States",
      noSaleStates: "No Sale States",
      underwriter: "Under Writer",
      action: (
        <ActionMenu
          items={[
            {
              name: "Add Agent",
              icon: <i className=" ri-add-circle-fill text-success mx-1 h4" />,
              color: "warning",
              action: () => {},
            },
            {
              name: "Update Agent",
              icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
              color: "primary",
              action: () => {},
            },
            {
              name: "Delete Agent",
              icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
              color: "danger",
              action: () => {},
            },
          ]}
        />
      ),
    },
  ];
  const columnsName = [
    {
      dataField: "parent_id",
      text: "Parent ID",
    },
    {
      dataField: "agent_id",
      text: "Agent ID",
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "product_label",
      text: "Label",
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "active",
      text: "Active",
    },
    {
      dataField: "defaultNoSaleStates",
      text: "Default No Sale States",
    },
    {
      dataField: "noSaleStates",
      text: "No Sale States",
    },
    {
      dataField: "underwriter",
      text: "Under Writer",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Products " breadcrumbItems={breadcrumbItems} />
        <PrimaryTable
          columnsName={columnsName}
          itemPerPage={itemPerPage}
          tableTitle={"Product List"}
          tableData={tableData}
          addButtonName="Add Product"
          setItemPerPage={setItemPerPage}
        />
      </Container>
    </div>
  );
};

export default ProductList;
