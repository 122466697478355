import { useState } from "react";
import { Container } from "reactstrap";
import ActionMenu from "../../../components/Common/ActionMenu";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PrimaryTable from "../../../components/Common/CommonTable/PrimaryTable";

const Users = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Users", link: "#" },
  ]);
  const [itemPerPage, setItemPerPage] = useState(10);

  const tableData = [
    {
      user_id: 1,
      user_name: "User User",
      user_type: "Primary",
      phone: "365 254 2589",
      email: "user10@mailinator.com",
      create_date: "08/11/2022",
      action: (
        <ActionMenu
          items={[
            {
              name: "Update details",
              icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
              color: "primary",
              action: () => {},
            },
            {
              name: "Delete user",
              icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
              color: "danger",
              action: () => {},
            },
          ]}
        />
      ),
    },
  ];
  const columnsName = [
    {
      dataField: "user_id",
      text: "User ID",
    },
    {
      dataField: "user_name",
      text: "User Name",
    },
    {
      dataField: "user_type",
      text: "User Type",
    },
    {
      dataField: "phone",
      text: "Phone No",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "create_date",
      text: "Create Date",
    },
    {
      dataField: "action",
      text: "Action ",
    },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItems={breadcrumbItems} />
          <PrimaryTable
            columnsName={columnsName}
            itemPerPage={itemPerPage}
            tableTitle={"User List"}
            tableData={tableData}
            addButtonName="Add User"
            setItemPerPage={setItemPerPage}
          />
        </Container>
      </div>
    </>
  );
};
export default Users;
