import React, { Fragment } from 'react'
import { useField } from 'formik';
import { Input, FormFeedback, Label } from 'reactstrap';
export default function InputField({ label, ...props }) {
    const [field, meta] = useField(props);
    return (
        <Fragment>
            {label && <Label htmlFor={props.name}>{label}</Label>}
            <Input {...field} {...props} invalid={meta.touched && meta.error} />
            {meta.touched && meta.error
                ?
                <FormFeedback>{meta.error}</FormFeedback>
                :
                null
            }
        </Fragment>
    )
}
