import React from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { PaginationListStandalone } from "react-bootstrap-table2-paginator";

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

const PrimaryTable = ({ columnsName, loading, itemPerPage, tableTitle, tableData, addButtonName, setItemPerPage }) => {
  const [open, setOpen] = useState(false);
  const itemPerPageOption = [10, 30, 50, 100];
  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader className="bg-white border-bottom">
              <div className="d-flex justify-content-between my-2">
                <div className="d-flex align-items-center fw-bold" style={{ fontSize: "18px" }}>
                  <i className="ri-file-list-line mx-2 my-1" />
                  {tableTitle}
                </div>
                <Button type="button" color="success" onClick={() => {}} className="btn-rounded mx-2">
                  <i className="mdi mdi-plus me-1" /> {addButtonName}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <React.Fragment>
                <Row className="mb-2">
                  <Col sm="8">
                    <div className="text-sm-start align-items-center">
                      <ButtonGroup className="me-1">
                        <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
                          <DropdownToggle tag="button" className="btn btn-light">
                            <span className="mx-1">{itemPerPage}</span> <i className="mdi mdi-chevron-down mx-1"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            {itemPerPageOption.map((item, index) => (
                              <DropdownItem
                                className={`d-flex align-items-center ${itemPerPage === item ? "text-success" : ""}`}
                                onClick={() => setItemPerPage(item)}
                                key={index}
                              >
                                <i className={`ri-record-circle-${itemPerPage === item ? "fill mx-2" : "line mx-2"}`} />
                                {item} result per page
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>{" "}
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="search-box me-2 mb-2 d-flex justify-content-end">
                      <div className="position-relative">
                        <div className="border border-secondary d-flex align-items-center p-1 rounded-pill">
                          <i className="ri-search-line mx-2 my-1" />
                          <input type="text" placeholder="Search" className="border-0 " />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="overflow-auto">
                  {loading ? (
                    <div>loading</div>
                  ) : (
                    <BootstrapTable
                      keyField="id"
                      responsive
                      bordered={false}
                      striped={false}
                      classes={"table align-middle table-nowrap table-check"}
                      headerWrapperClasses={"table-light"}
                      data={tableData}
                      columns={columnsName}
                    />
                  )}
                </div>
                <div className="pagination pagination-rounded justify-content-end mb-2">
                  <PaginationListStandalone
                  //   {...paginationProps}
                  />
                </div>
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PrimaryTable;
