import { Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../components/Common/FormControl/InputField";
import { Button, Col, FormGroup, Row } from "reactstrap";
import React, { useState } from "react";

const ChangePassword = ({
  loading,
  imagePreview,
  projectNames,
  onDropImages,
  deleteFile,
  handelSubmit,
  openModal,
  setOpenModal,
}) => {
  const [dropEffect, setDropEffect] = useState(false);
  const [imgZoom, setImgZoom] = useState(false);

  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };
  const validationSchema = yup.object().shape({
    old_password: yup.string().strict(true).trim().required("This is required field"),
    new_password: yup.string().strict(true).trim().required("This is required field"),
    confirm_password: yup.string().strict(true).trim().required("This is required field"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSubmit}
      enableReinitialize
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <Row className="mx-1">
            <Col lg={6}>
              <FormGroup className="my-2">
                <InputField
                  label="Current Password"
                  type="text"
                  name="old_password"
                  id="old_password"
                  placeholder="Old Password"
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup className="my-2">
                <InputField
                  label="New Password"
                  type="text"
                  name="new_password"
                  id="new_password"
                  placeholder="New Password"
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup className="my-2">
                <InputField
                  label="Confirm Password"
                  type="text"
                  name="confirm_password"
                  id="confirm_password"
                  placeholder="Confirm Password"
                />
              </FormGroup>
            </Col>
            <Col lg={12} className="d-flex justify-content-end">
              <Button color="success" className="my-3 d-flex align-items-center">
                <i className="ri-edit-2-fill mx-2" />
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
