import { useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Apis = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Apis", link: "#" },
  ]);
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Apis" breadcrumbItems={breadcrumbItems} />
        </Container>
      </div>
    </>
  );
};
export default Apis;
