import { Button, Col, Row } from "reactstrap";
import RatingTooltip from "react-rating-tooltip";
import { useNavigate } from "react-router-dom";

const vendorContentTemplate = (icon, label, value) => {
  return (
    <div className="bg-light h6 rounded-2 py-3 px-3 d-flex align-items-center">
      <i className={`text-success ${icon}`} />
      <span className="fw-bolder text-primary mx-1">{label} :</span>
      <span>{value}</span>
    </div>
  );
};

const AccountInfo = () => {
  const navigate = useNavigate();
  return (
    <Row>
      <Col md="3">
        <div className="w-100 p-3">
          <img
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            alt="Profile_Image"
            className="w-100 rounded-2"
          />
        </div>
      </Col>
      <Col md="9">
        <div className="m-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="fs-5 fw-bold">Tarzan Olive</div>
              <div className="mx-2 text-secondary">
                <small className="mx-1">
                  <i className="ri-user-location-fill" />
                </small>
                <small>West Bengal, Kolkata</small>
              </div>
            </div>
            <div>
              <Button color="danger" className="py-1 d-flex align-items-center" onClick={() => navigate("/login")}>
                <i className="ri-logout-box-r-line mx-2" /> Logout
              </Button>
            </div>
          </div>

          <small className="fw-bold text-info">Super Admin</small>

          <div className="my-2">
            <div>
              <small>Ranking</small>
            </div>
            <div className="d-flex justify-content-start align-items-center">
              <RatingTooltip
                max={5}
                // onChange={(rate) => this.setState({ default: rate })}
                ActiveComponent={<i key={"active_3"} className="mdi mdi-star text-primary" />}
                InActiveComponent={<i key={"active_03"} className="mdi mdi-star-outline text-muted" />}
                defaultRating={4}
              />
            </div>
          </div>

          <div className="my-2">
            <div>
              <small>User Name</small>
            </div>
            <div className="d-flex justify-content-start align-items-center">Tarzan@mailinator.com</div>
          </div>
        </div>
      </Col>
      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-mail-fill", "Email", "Tarzan@mailinator.com")}
      </Col>
      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-phone-line", "Phone No", "+91 142-563-9874")}
      </Col>

      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-home-3-fill", "City", "Kolkata")}
      </Col>
      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-home-3-fill", "State", "West Bengal")}
      </Col>
      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-home-3-fill", "Zip", "25634")}
      </Col>
      <Col xl="6" className="my-2">
        {vendorContentTemplate("ri-home-3-fill", "Address", "address_1")}
      </Col>
    </Row>
  );
};
export default AccountInfo;
