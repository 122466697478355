import { useState } from "react";
import { Container } from "reactstrap";
import ActionMenu from "../../../components/Common/ActionMenu";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PrimaryTable from "../../../components/Common/CommonTable/PrimaryTable";

const LicensesList = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Licenses List", link: "/licenses/list" },
  ]);
  const [itemPerPage, setItemPerPage] = useState(10);

  const tableData = [
    {
      agent_id: 1,
      license_id: 5,
      type: "any",
      number: "Tarzan Oliv",
      exp_date: "02/02/2029",
      sus_date: "02/02/2019",
      sus_reason: "Default No Sale States",
      state: "No Sale States",
      Issue_date: "02/02/2019",
      action: (
        <ActionMenu
          items={[
            {
              name: "Add Agent",
              icon: <i className=" ri-add-circle-fill text-success mx-1 h4" />,
              color: "warning",
              action: () => {},
            },
            {
              name: "Update Agent",
              icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
              color: "primary",
              action: () => {},
            },
            {
              name: "Delete Agent",
              icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
              color: "danger",
              action: () => {},
            },
          ]}
        />
      ),
    },
  ];
  const columnsName = [
    {
      dataField: "agent_id",
      text: "Agent ID",
    },
    {
      dataField: "license_id",
      text: "License ID",
    },
    {
      dataField: "type",
      text: "Type ",
    },
    {
      dataField: "number",
      text: "Number",
    },
    {
      dataField: "exp_date",
      text: "Exp Date",
    },
    {
      dataField: "sus_date",
      text: "Sus Date",
    },
    {
      dataField: "sus_reason",
      text: "Sus Reason ",
    },
    {
      dataField: "state",
      text: "State",
    },
    {
      dataField: "Issue_date",
      text: "Issue Date",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Licenses " breadcrumbItems={breadcrumbItems} />
        <PrimaryTable
          columnsName={columnsName}
          itemPerPage={itemPerPage}
          tableTitle={"Licenses List"}
          tableData={tableData}
          addButtonName="Add Licenses"
          setItemPerPage={setItemPerPage}
        />
      </Container>
    </div>
  );
};
export default LicensesList;
