import { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import AccountInfo from "./AccountInfo";
import { useNavigate, useParams } from "react-router-dom";
import { ACCOUNT_INFO, CHANGE_PASSWORD, UPDATE_INFO } from "../../constant/RouteURL/Profile";
import ChangePassword from "./ChangePassword";
import UpdateInfo from "./UpdateInfo";

const UserProfile = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "UserProfile", link: "/user-profile/info" },
  ]);
  // -------------------------------Tab Header Item-----------------------------------
  const tabItem = [
    {
      name: "Account Information",
      icon: "ri-information-line",
      url: ACCOUNT_INFO,
    },
    {
      name: "Change Password",
      icon: " ri-lock-password-line",
      url: CHANGE_PASSWORD,
    },
    {
      name: "Update Information",
      icon: "ri-edit-2-line",
      url: UPDATE_INFO,
    },
  ];

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="User Profile" breadcrumbItems={breadcrumbItems} />
        <Card>
          <CardBody>
            <Row>
              
              <Col md="12">
                {/* -------------------------------------Tabs----------------------------------- */}
                <Nav tabs>
                  {tabItem.map((item, index) => (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: name === item.url,
                        })}
                        onClick={() => navigate(`/user-profile/${item.url}`)}
                      >
                        <div className="d-flex">
                          <i className={`${item.icon} mx-2`} />
                          <span className="d-none d-sm-block">{item.name}</span>
                        </div>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={name}>
                  <TabPane tabId={ACCOUNT_INFO} className="p-3">
                    <Row>
                      <Col sm="12">
                        {/* -------------------------------Account Info-------------------------- */}
                        <AccountInfo />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={CHANGE_PASSWORD} className="p-3">
                    <Row>
                      <Col sm="12">
                        {/* ------------------------------Change Password-------------------------- */}
                        <ChangePassword />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={UPDATE_INFO} className="p-3">
                    <Row>
                      <Col sm="12">
                        {/* --------------------------------Update Info---------------------------- */}
                        <UpdateInfo />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
              {/* <Col md="12" className="my-4">
                <ProfileUI />
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
export default UserProfile;
