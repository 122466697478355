import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "../../store/actions";

const Logout = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    // Fire Action for Remove all Item from localstorage and redirect to login page
    // props.logoutUser(props.navigate);/login
    navigate("/login");
  }, [props, navigate]);

  return (
    <React.Fragment>
      <h1>&nbsp;</h1>
    </React.Fragment>
  );
};

export default connect(null, { logoutUser })(Logout);
