import React, { Component } from "react";
import { Row, Col, Alert, Button, Container, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";

const ForgetPasswordPage = () => {
  const handleSubmit = (event, values) => {
    console.log(values);
  };
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              {/* <img src={logodark} height="20" alt="logo" />
                               */}
                              <img src="/favicon.png" alt="" height="40" />

                              {/* <p className="h3 text-primary mt-3 pt-1 d-flex justify-content-center">
                                <i className="ri-settings-2-line text-danger"></i>
                                <span className="mx-1 ">SLEEK</span>
                              </p> */}
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Reset Password</h4>
                          <p className="text-muted">Reset your password to Sleek.</p>
                        </div>

                        <div className="p-2 mt-5">
                          {/* {this.props.forgetError && this.props.forgetError ? (
                              <Alert color="danger" className="mb-4">
                                {this.props.forgetError}
                              </Alert>
                            ) : null}
                            {this.props.message ? (
                              <Alert color="success" className="mb-4">
                                {this.props.message}
                              </Alert>
                            ) : null} */}
                          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                            <div className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auti-custom-input-icon"></i>
                              <Label htmlFor="useremail">Email</Label>
                              <AvField
                                name="useremail"
                                value={""}
                                type="email"
                                validate={{ email: true, required: true }}
                                className="form-control"
                                id="useremail"
                                placeholder="Enter email"
                              />
                            </div>

                            <div className="mt-4 text-center">
                              <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                {false ? "Loading..." : "Reset"}
                              </Button>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Don't have an account ?
                            <Link to="/login" className="fw-medium text-primary">
                              Log in
                            </Link>
                          </p>
                          <p>© {new Date().getFullYear()} SLEEK Dental, All rights reserved.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg">
                <div className="bg-overlay"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage);
