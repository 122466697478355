import { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TreeUi from "./TreeUi";
const AgentsTree = () => {
  const [breadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Tree", link: "#" },
  ]);

  const data = [
    {
      id: 1,
      name: "Samsung",
      children: [
        {
          id: 11,
          name: " Samsung Galaxy S Series",
          children: [
            { id: 111, name: "Samsung Galaxy s1", children: [] },
            { id: 111, name: "Samsung Galaxy s2", children: [] },
            { id: 111, name: "Samsung Galaxy s3", children: [] },
            { id: 111, name: "Samsung Galaxy s4", children: [] },
            { id: 111, name: "Samsung Galaxy s5", children: [] },
            { id: 111, name: "Samsung Galaxy s6", children: [] },
            { id: 111, name: "Samsung Galaxy s7", children: [] },
            { id: 111, name: "Samsung Galaxy s8", children: [] },
            { id: 111, name: "Samsung Galaxy s9", children: [] },
            { id: 111, name: "Samsung Galaxy s10", children: [] },
            { id: 111, name: "Samsung Galaxy s20", children: [] },
          ],
        },
        {
          id: 11,
          name: "Samsung Galaxy J Series",
          children: [
            { id: 111, name: "Samsung Galaxy J1", children: [] },
            { id: 111, name: "Samsung Galaxy J2", children: [] },
            { id: 111, name: "Samsung Galaxy J3", children: [] },
            { id: 111, name: "Samsung Galaxy J4", children: [] },
            { id: 111, name: "Samsung Galaxy J5", children: [] },
            { id: 111, name: "Samsung Galaxy J6", children: [] },
            { id: 111, name: "Samsung Galaxy J7", children: [] },
            { id: 111, name: "Samsung Galaxy J8", children: [] },
          ],
        },
        {
          id: 11,
          name: "Samsung Galaxy Fold Series",
          children: [
            { id: 111, name: "Samsung Galaxy Fold", children: [] },
            { id: 111, name: "Samsung Galaxy Fold 2", children: [] },
            { id: 111, name: "Samsung Galaxy Fold 3", children: [] },
            { id: 111, name: "Samsung Galaxy Fold 4", children: [] },
          ],
        },
      ],
    },
  ];
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tree" breadcrumbItems={breadcrumbItems} />
          <Card>
            <div className="mx-4 my-3 h5">Tree Body</div>
            <CardBody style={{ marginRight: "10px" }}>
              {data.map((item, index) => (
                <TreeUi key={index} treeData={item} id={index + 1} />
              ))}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};
export default AgentsTree;
