import React from "react";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
// import useSectionPermission from "../../../hooks/useSectionPermission";

const ActionMenu = ({ items, currentMenuName }) => {
  // const { RBAC } = useSectionPermission(currentMenuName);
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        size="sm"
        color="neutral-first"
        className=" px-2 py-0"
      >
        <i className="ri-more-2-fill text-primary h4 fw-bolder "></i>
      </DropdownToggle>
      <DropdownMenu
        right
        className="dropdown-menu-sm border-0 overflow-hidden p-0"
      >
        <Nav pills className="nav-neutral-first flex-column ">
          <NavItem className="nav-item--header px-3">
            <span className="text-capitalize text-black font-size-sm font-weight-bold px-2">
              Action(s)
            </span>
          </NavItem>
          {items.map(({ name, icon, color, action, actionName }, ind) => (
            // <RBAC action={actionName} key={ind}>
              <NavItem className="border-top" >
                <NavLink
                  onClick={action}
                  className={`text-${color} click-text d-flex align-items-center`}
                >
                  <div className="nav-link-icon">
                  {icon}
                  </div>
                  <span className="font-size-sm">{name}</span>
                </NavLink>
              </NavItem>
            // </RBAC>
          ))}
        </Nav>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionMenu;
