import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
export default function NonAuthLayout({children}) {
   const location =  useLocation()
  useEffect(() => {
    let currentage = capitalizeFirstLetter(location.pathname);
    currentage = currentage.replaceAll("-", " ");

    document.title = currentage + " | Nazox - Responsive Bootstrap 5 Admin Dashboard";
  }, []);
  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };
  return <>{children}</>;
}
