import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";
const PrimaryInfoCard = ({ cardTitle, detailsData }) => {
  return (
    <Card>
      <CardHeader className="bg-white border-bottom">
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex align-items-center fw-bold" style={{ fontSize: "18px" }}>
            <i className="ri-file-list-line mx-2 my-1" />
            {cardTitle}
          </div>
        </div>
      </CardHeader>
      <SimpleBar style={{ maxHeight: "60vh" }}>
        <CardBody className="p-0">
          <Row>
            {detailsData.map(({ label, value }, index) => (
              <Col xl="6">
                <div key={index} className="bg-light m-3  rounded-1" style={{ padding: "12px 15px" }}>
                  {/* <i className="ri-record-circle-line mx-2" /> */}
                  <span className="h6">{label}:</span>
                  <span className="mx-1">{value}</span>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </SimpleBar>
    </Card>
  );
};
export default PrimaryInfoCard;
