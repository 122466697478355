import { useState } from "react";
import { Button, Card } from "reactstrap";
import ActionMenu from "../../../components/Common/ActionMenu";

const TreeUi = ({ treeData, id }) => {
  const [openId, setOpenId] = useState(false);

  const ActionGroup = () => {
    return (
      <div className="mx-3 py-1 px-2 border" style={{ borderRadius: "6px", cursor: "pointer" }}>
        <i className=" ri-add-circle-fill text-success mx-2 h4 "></i>
        <i className="ri-edit-2-fill text-primary mx-2 h4 "></i>
        <i className="ri-delete-bin-fill text-danger mx-2 h4"></i>
      </div>
    );
  };

  return (
    <div style={{ marginLeft: "30px" }} className="border">
      <div className="h6">
        <Card className="p-2 ">
          <div className="d-flex align-items-center">
            <Button
              onClick={() => setOpenId((pre) => (pre === false ? id : false))}
              color={openId !== id ? "success" : "primary"}
              className="px-2 py-0 mx-3"
            >
              {/* {openId !== id ? "+" : "-"} */}
              <i className={`fas fa-angle-${openId !== id ? "down" : "right"}`}></i>
            </Button>
            <div>
              <img
                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                alt=""
                className="mx-2"
                style={{ height: "35px", borderRadius: "6px" }}
              />
            </div>
            <div>{treeData?.name}</div>
          <div className="d-none d-lg-block"> {ActionGroup()}</div> 
          <div className="d-block d-lg-none">   <ActionMenu
              items={[
                {
                  name: "Add Agent",
                  icon: <i className=" ri-add-circle-fill text-success mx-1 h4" />,
                  color: "warning",
                  action: () => {},
                },
                {
                  name: "Update Agent",
                  icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
                  color: "primary",
                  action: () => {},
                },
                {
                  name: "Delete Agent",
                  icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
                  color: "danger",
                  action: () => {},
                },
              ]}
            /></div> 
          </div>
        </Card>

        {openId !== id
          ? treeData?.children?.map((item, ind) => <TreeUi key={ind} treeData={item} id={ind + 1} />)
          : null}
      </div>
    </div>
  );
};

export default TreeUi;
