import { useState } from "react";
import { Container } from "reactstrap";
import ActionMenu from "../../components/Common/ActionMenu";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PrimaryTable from "../../components/Common/CommonTable/PrimaryTable";

const Plans = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { title: "Sleek", link: "/" },
    { title: "Plans", link: "/plans" },
  ]);
  const [itemPerPage, setItemPerPage] = useState(10);

  const tableData = [
    {
      Plan_name: "Gold",
      main_plan: "Yes",
      monthly_price: "$ 13",
      yearly_price: "$ 110",
      maximum_users: "5",
      status: "Active",
      action: (
        <ActionMenu
          items={[
            {
              name: "Update Plan",
              icon: <i className="ri-edit-2-fill text-primary mx-1 h4 " />,
              color: "primary",
              action: () => {},
            },
            {
              name: "Delete Plan",
              icon: <i className="ri-delete-bin-fill text-danger mx-2 h4" />,
              color: "danger",
              action: () => {},
            },
          ]}
        />
      ),
    },
  ];
  const columnsName = [
    {
      dataField: "Plan_name",
      text: "Plan Name",
    },
    {
      dataField: "main_plan",
      text: "Main Plan",
    },
    {
      dataField: "monthly_price",
      text: "Monthly Price",
    },
    {
      dataField: "yearly_price",
      text: "Yearly Price",
    },
    {
      dataField: "maximum_users",
      text: "Maximum Users",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ];
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Plans" breadcrumbItems={breadcrumbItems} />
          <PrimaryTable
            columnsName={columnsName}
            itemPerPage={itemPerPage}
            tableTitle={"Plan List"}
            tableData={tableData}
            addButtonName="Add Plan"
            setItemPerPage={setItemPerPage}
          />
        </Container>
      </div>
    </>
  );
};
export default Plans;
