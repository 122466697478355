import React, { useEffect } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";

function SidebarContent(props) {
  const location = useLocation();
  useEffect(() => {
    initMenu();
  }, []);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")}</li>

          {/* -----------------------------------Dashboard---------------------------- */}
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="ri-dashboard-line"></i>
              {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
              <span className="ms-1">{props.t("Dashboard")}</span>
            </Link>
          </li>
          {/*---------------------------------------------------------------------------*/}

          {/* ------------------------------------Agents------------------------------- */}
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ri-profile-line"></i>
              <span className="ms-1">{props.t("Agents")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/agents-tree">{props.t("Tree")}</Link>
              </li>
              <li>
                <Link to="/agents-list">{props.t("List")}</Link>
              </li>
            </ul>
          </li>
          {/*---------------------------------------------------------------------------*/}

          {/* ------------------------------------Plans-------------------------------- */}
          <li>
            <Link to="/plans" className="waves-effect">
              <i className="ri-account-circle-line"></i>
              {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
              <span className="ms-1">{props.t("Plans")}</span>
            </Link>
          </li>
          {/*----------------------------------------------------------------------------*/}

          {/* ------------------------------------Product-------------------------------- */}
          <li>
            <Link to="/product/list" className="waves-effect">
              <i className=" ri-product-hunt-line"></i>
              {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
              <span className="ms-1">{props.t("Products")}</span>
            </Link>
          </li>
          {/*----------------------------------------------------------------------------*/}

          {/* ------------------------------------Licenses-------------------------------- */}
          <li>
            <Link to="/licenses/list" className="waves-effect">
              <i className="ri-bank-card-2-fill"></i>
              {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
              <span className="ms-1">{props.t("Licenses")}</span>
            </Link>
          </li>
          {/*----------------------------------------------------------------------------*/}

          {/* ------------------------------------Setting------------------------------- */}
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="ri-profile-line"></i>
              <span className="ms-1">{props.t("Settings")}</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/apis">{props.t("APIs")}</Link>
              </li>
              <li>
                <Link to="/users">{props.t("Users")}</Link>
              </li>
            </ul>
          </li>
          {/*----------------------------------------------------------------------------*/}
        </ul>
      </div>
    </React.Fragment>
  );
}
const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader,
})(withNamespaces()(SidebarContent));
