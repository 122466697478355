import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";

const AppRoute = ({ isAuthProtected, layout: Layout, ...props }) => {
  if (isAuthProtected && !localStorage.getItem("authUser")) {
    return <Navigate to={{ pathname: "/login", state: { from: "props.location" } }} />;
  }
  return <Layout><Outlet /></Layout>;
};
export default AppRoute;
