import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../components/Common/FormControl/InputField";
import { Button, Col, FormGroup, Row } from "reactstrap";

const UpdateInfo = ({ handelSubmit }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    state: "",
    city: "",
    zip_code: "",
    address: "",
  };
  const validationSchema = yup.object().shape({
    first_name: yup.string().strict(true).trim().required("This is required field"),
    last_name: yup.string().strict(true).trim().required("This is required field"),
    email: yup.string().strict(true).trim().required("This is required field"),
    phone_number: yup.string().strict(true).trim().required("This is required field"),
    state: yup.string().strict(true).trim().required("This is required field"),
    city: yup.string().strict(true).trim().required("This is required field"),
    zip_code: yup.string().strict(true).trim().required("This is required field"),
    address: yup.string().strict(true).trim().required("This is required field"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handelSubmit}
      enableReinitialize
    >
      {({ setFieldValue, values, touched, errors }) => (
        <Form>
          <Row>
            <Col md="4" xl="3">
              <div className="w-100 p-3">
                <img
                  src="https://www.freecodecamp.org/news/content/images/2021/03/Quincy-Larson-photo.jpg"
                  alt="Profile_Image"
                  className="w-100 rounded-2"
                />
                <div className="d-flex justify-content-between">
                  <Button className="my-3 mx-1 w-50">Add</Button>
                  <Button color="danger" className="my-3 mx-1 w-50">
                    Delete
                  </Button>
                </div>
              </div>
            </Col>
            <Col md="8" xl="9">
              <Row>
                <Col lg={6}>
                  <FormGroup className="my-2">
                    <InputField
                      label="First Name"
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="First Name"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="my-2">
                    <InputField label="Last Name" type="text" name="last_name" id="last_name" placeholder="Last Name" />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="my-2">
                    <InputField label="Email" type="text" name="email" id="email" placeholder="Email" />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className="my-2">
                    <InputField
                      label="Phone No"
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Phone No"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="my-2">
                    <InputField label="State" type="text" name="state" id="state" placeholder="State" />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="my-2">
                    <InputField label="City" type="text" name="city" id="city" placeholder="City" />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="my-2">
                    <InputField label="Zip Code" type="text" name="zip_code" id="zip_code" placeholder="Zip Code" />
                  </FormGroup>
                </Col>
                <Col md="12">
              <FormGroup className="my-2">
                <InputField
                  label="Address"
                  type="textarea"
                  rows="5"
                  name="address"
                  id="address"
                  placeholder="Address"
                />
              </FormGroup>
            </Col>
              </Row>
            </Col>
           
            <Col lg={12} className="d-flex justify-content-end">
              <Button color="success" className="my-3 d-flex align-items-center">
                <i className="ri-edit-2-fill mx-2" />
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateInfo;
