import { API_BASE_URL } from "./connection";

const Apis = {
  agent: {
    list: `${API_BASE_URL}/v2/agents/650326/downline?mode=all`,
    details: (id) => `${API_BASE_URL}/v2/agents/${id}`,
  },
};

export default Apis;
